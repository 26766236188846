import 'es6-promise/auto' // Needed for page to load, even message will not load without it.  Tied to issue with esriLoader not passing promise
import 'es6-object-assign/auto'
import { loadModules } from 'esri-loader';
import _get from 'lodash/get';
import { appConfig } from './config';
import { aboutHTML } from './content';
import {
   filterStylingByFeatures,
   inspectFeatureStyles,
   reformatStyles
} from './weatherLayerFilters';

loadModules([
   'esri/config',
   'esri/views/MapView',
   'esri/WebMap',
   'esri/widgets/Search',
   'esri/widgets/LayerList',
   'esri/widgets/Expand',
   'esri/widgets/Locate',
   'esri/widgets/Zoom',
   'esri/tasks/Locator'
])
.then(([esriConfig, MapView, WebMap, Search, LayerList, Expand, Locate, Zoom, Locator]) => {

   // get portal url from config
   esriConfig.portalUrl = appConfig.get('portalUrl');

   // add new web map instance using portal map id
   const webmap = new WebMap({
      portalItem: { // autocasts as new PortalItem()
         id: appConfig.get('ltlServiceMapId')
      }
   });

   // add the map to a view and place in the dom (id #viewDiv)
   const view = new MapView({
      container: "viewDiv",
      map: webmap,
      center: appConfig.get('mapCenter'),
      zoom: appConfig.get('mapZoom')
   });

   // set popup options
   view.popup.dockOptions = {
      // enables/disables the dock button from the popup
      // buttonEnabled: false,
      // change default popup docking position
      position: 'bottom-right'
   };

   // Only attribution on the map to start with. We will add and position all the widgets below
   view.ui.components = [ "attribution" ];

   // wait until view loads & then add additional widgets & styling
   return view.when(function() { // when the resources in the MapView have loaded

      // get layers
      const boundariesLayer = webmap.layers.getItemAt(0);
      const weatherWatchesLayer = webmap.layers.getItemAt(1);
      const weatherWarningsLayer = webmap.layers.getItemAt(2);
      const freezeEmbargoLayer = webmap.layers.getItemAt(3);
      const serviceCenterLayer = webmap.layers.getItemAt(4);

      // remove labels from service center layer --- commented out since this was changed in the source map
      // but leaving here for future reference if needed
      // serviceCenterLayer.labelsVisible = false;

      // hide boundaries from layer lists
      boundariesLayer.listMode="hide";
      // freezeEmbargoLayer.listMode="hide"; // Uncomment to hide FreezeEmbargo from legend! Comment this line out to enable Freeze Embargo in legend!
      // Change the cursor to a hand (pointer) when the user hovers over a clickable feature
      view.on("pointer-move", function(event) {
         view.hitTest(event).then(function(response) {
            // check if a feature is returned from the serviceCenterLayer
            // or one of the weather layers. If so, make the cursor a hand (pointer)
            const clickableFeatures = response.results.filter(function(result) {
               let layer = result.graphic.layer;
               return (layer === serviceCenterLayer || layer === weatherWatchesLayer || layer === weatherWarningsLayer);
            });
            if (clickableFeatures.length > 0) {
               document.getElementById("viewDiv").style.cursor = 'pointer';
            }
            else {
               document.getElementById("viewDiv").style.cursor = 'default';
            }
         })
         .catch(err => {
            // handle any errors
            console.error(err);
         });
      });

      // update weather warning styling data
      weatherWarningsLayer
         .when(function() {
            // remove 'Special Marine Warning'
            weatherWarningsLayer.renderer.removeUniqueValueInfo('MA,W');
         })
         .catch(err => console.log(err.message));

      // update weather watch styling data
      weatherWatchesLayer
         .when(function() {
            // retrieve features
            let query = weatherWatchesLayer.createQuery();
            query.returnGeometry = false; //limits query to just attribute data, no geometry
            return weatherWatchesLayer.queryFeatures(query);
         })
         .then(function(response) {
            console.log(`response received`);
            // get current styling options from layer renderer
            const weatherLayerRenderer = weatherWatchesLayer.get('renderer');

            // get current features
            const features = _get(response, 'features', []);

            // // inspect features (optional - for testing only)
            // console.log(inspectFeatureStyles(weatherLayerRenderer, features));

            // filter styling by existing features
            const filteredRenderer = filterStylingByFeatures(
               weatherLayerRenderer,
               features
            );

            // reformat renderer to use feature label data
            const reformattedRenderer = reformatStyles(filteredRenderer);

            weatherWatchesLayer.renderer = reformattedRenderer;
         })
         .catch(err => console.log(err.message));
      // Add Widgets to the bottom right corner
      // Add Zoom widget
      const zoomWidget = new Zoom({
         view: view
      });

      // Add the Zoom widget to the view
      view.ui.add(zoomWidget, {
         position: "bottom-right",
      });

      // Add Locate Me widget
      const locateWidget = new Locate({
         view: view
      });

      // Add the locate widget to the view
      // Need to position this one manually because arcgis js api positions bottom row icons in a horizontal patten
      view.ui.add(locateWidget, {
         position: "manual",
      });

      // Add Widgets to the top right corner
      // Add a layer list widget
      const layerListWidget = new LayerList({
         container: document.createElement("div"),
         view,
         listItemCreatedFunction: function(event) {
            const item = event.item;
            item.panel = {
               content: "legend",
               open: (item.title !== 'Current Weather Warnings')
            };
         }
      });

      // Add layerlist as expandable element
      const layerListExpand = new Expand({
         expandIconClass: "esri-icon-layers",  // see https://developers.arcgis.com/javascript/latest/guide/esri-icon-font/
         expandTooltip: "Legend",
         collapseTooltip: "Collapse Legend",
         expanded: (screen.width >= 1024), // legend is expanded by default unless we are on a small device (width < 1024px)
         view: view,
         content: layerListWidget.domNode
      });

      // Add layer list widget to the view
      view.ui.add(layerListExpand, {
         position: "top-right",
      });

      // Add search widget
      // - allow searching of service center layer sic attr
      const searchWidget = new Search({
         view: view,
         allPlaceholder: "Find a Location or Terminal",
         activeSourceIndex: -1,
         includeDefaultSources: false,
         sources: [
            {
               layer: serviceCenterLayer,
               searchFields: ["terminal_name"],
               displayField: "terminal_name",
               exactMatch: false,
               outFields: ["terminal_name"],
               name: "XPO Terminal",
               placeholder: "XPO Terminal name",
               maxSuggestions: 25,
               suggestionsEnabled: true
            },
            {
               locator: new Locator({  // ESRI world Geolocator for searching generic locations
                  url: "http://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer",
                  popupTemplate: { overwriteActions: true },
               }),
               name: "Locations",
               maxSuggestions: 25,
               placeholder: "City or Address"
            }
         ]
      });

      // Add widgets to the top left corner of the view
      // Add search widget to the view
      view.ui.add(searchWidget, {
         position: "top-left",
      });

      // Add About widget (expand widget really)
      // Displays instructions to the user for understanding the map
      // And places them in an Expand widget instance
      const aboutMapDiv = document.createElement("div");
      aboutMapDiv.style.padding = "10px";
      aboutMapDiv.style.backgroundColor = "white";
      aboutMapDiv.style.width = "500px";
      aboutMapDiv.innerHTML = aboutHTML;

      const instructionsExpand = new Expand({
         expandIconClass: "esri-icon-description",
         expandTooltip: "About this map",
         view: view,
         content: aboutMapDiv
      });

      // add about widget to the view
      view.ui.add(instructionsExpand, "top-left");
   });
})
.catch(err => {
   // handle any errors
   console.error(err);
});

// After page is loaded, check if map is unselectable/unviewable,
//   if so, make announcement visible and move ontop of map so it's selectable.
window.onload = function () {
   var slt = document.getElementsByClassName('esri-view-surface').item(0); //map element
   var b_txt = document.getElementById('browser-alert') // service announcement
   // 'unselectable' is attribute for IE11 that is present when map does not render
   // '-ms-user-select' is attribute for Edge that is present when map does not render. Attribute is a style attribute accessed with msUserSelect.
   if ((slt.getAttribute('unselectable') == 'on') || (slt.style.msUserSelect == 'none')) {
      b_txt.style.visibility = 'visible';
      b_txt.style.zIndex = '999';
   };
};
