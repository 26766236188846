// app configuration data

export const appConfig = new Map([
   [`portalUrl`, process.env.MAP_URL],
   [`ltlServiceMapId`, process.env.MAP_ID],
   ['mapCenter', [-91.71511, 39.09042]], // longitude, latitude
   ['mapZoom', 5]
]);

export const weatherWatchWhitelist = {
   sig: new Set(['W', 'Y']),
   phenom: new Set(['CF', 'TR', 'BS', 'BZ', 'EW', 'FA', 'FF', 'HF', 'HI', 'HS', 'HU', 'IP',
      'IS', 'LB', 'LE', 'SB', 'SN', 'SR', 'SV', 'TI', 'TO', 'TR', 'TS', 'TY', 'WS', 'WW', 'ZF','ZR']),
   label: new Set([
      'Flood Warning',
      'Flood Watch'
   ])
}
