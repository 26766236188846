// styling filters for noaa weather watches and warnings layer
import _get from 'lodash/get';
import { weatherWatchAttWhitelist } from './config';


/* filters unique value renderer based on provided features
// params:
// - unique value renderer from layer [Object]
// - layer features [Array]
// returns new renderer [Object]
*/
export function filterStylingByFeatures(renderer = {}, features = []) {
   // determine attributes used for styling
   const stylingAttributes = getStylingKeys(renderer) || [];
   // get field delimiter
   const fieldDelimiter = _get(renderer, 'fieldDelimiter', ',');
   // get styling options
   const stylingOptions = _get(renderer, 'uniqueValueInfos', []);

   // keep track of unique style keys
   const uniqueWeatherKeys = new Set();

   // record unique styling keys to a JS Set
   features.forEach(function(feature) {
      // retrive attributes for style key
      const styleKey = stylingAttributes.map(function(attr) {
         const featureAttributes = _get(feature, 'attributes');
         return featureAttributes[attr];
      }).join(fieldDelimiter);

      // add style key to the set unless if it has not been added already
      if (!uniqueWeatherKeys.has(styleKey)) {
         uniqueWeatherKeys.add(styleKey);
      }
   });

   // filter style array based on unique keys
   const filteredStyles = stylingOptions.filter(function(style) {
      return uniqueWeatherKeys.has(style.value);
   });

   // construct new renderer & return it
   const newRenderer = renderer.clone();
   newRenderer.uniqueValueInfos = filteredStyles;
   return newRenderer;
}

/* helper function - return style key attributes array from a renderer
// takes a renderer object and returns the fields that determine styling
// params:
// - renderer (unique value) [Object]
// returns style key array [Array]
*/
export function getStylingKeys({
   field,
   field2,
   field3
}) {
   return [ field, field2, field3 ]
      .filter(field => !!field);
}

/* reformat the styling of a unique style renderer to use the label field
// primary created to try & deal with duplicate entries in the legend
// params:
// - unique value renderer from layer [Object]
// returns new renderer [Object]
// NOTE: the styling attributes must be in the order they will
//       be used to construct the new style key (value)
*/
export function reformatStyles(renderer = {}) {
   // retrieve styling options
   const stylingOptions = renderer.get('uniqueValueInfos') || [];
   // keep track of unique key values
   const uniqueWeatherKeys = new Set();

   // construct & return new style array
   const newStylingOptions = stylingOptions.reduce(function(acc, style) {
      const {
         description = '',
         label = '',
         symbol = {},
         value = ''
      } = style;
      // construct new key
      const newStyleKey = label;
      if (!uniqueWeatherKeys.has(newStyleKey)) {
         uniqueWeatherKeys.add(newStyleKey);
         return acc.concat({ description, label, symbol, value: newStyleKey });
      } else {
         return acc;
      }
   }, []);

   // construct new renderer & return
   return {
      type: 'unique-value',
      field: 'prod_type',
      defaultSymbol: null,
      defaultLabel: null,
      uniqueValueInfos: newStylingOptions
   };
}

/* filter the styling of a unique style renderer by style label
// allows you to filter styling options by the name (label) of the style
// params:
// - unique value renderer from layer [Object]
// - list of whitelisted watch/warning labels [Set]
// returns new renderer [Object]
*/
export function filterStylingByLabel(renderer = {}, ValidStyleLabels = new Set([])) {
   // retrieve styling options
   const stylingOptions = renderer.get('uniqueValueInfos') || [];
   // copy renderer
   const newRenderer = renderer.clone();

   // construct & return new style array
   const newStylingOptions = stylingOptions.filter(style => ValidStyleLabels.has(style.label));

   // construct new renderer & return
   newRenderer.uniqueValueInfos = newStylingOptions;
   return newRenderer;
}

/* feature inspection utility
// to help inspect weather map features and styling anomalies
// detailed output is sent to the browser developer console
// params:
// - unique value renderer from layer [Object]
// - layer features [Array]
// returns a Map of style keys that do no have corresponding style option
*/
export function inspectFeatureStyles(renderer = {}, features = []) {
   console.group(`%cfeature inspection utility`, 'color: yellow; font-weight: bold; background-color: blue;padding: 2px');
   // determine attributes used for styling
   const stylingAttributes = getStylingKeys(renderer) || [];
   // get field delimiter
   const fieldDelimiter = _get(renderer, 'fieldDelimiter', ',');
   // get styling options
   const stylingOptions = _get(renderer, 'uniqueValueInfos', []);

   // keep track of value keys that do not match any styling options
   const noStyleMatch = new Map();

   console.group(`%cinspecting weather features...`, 'color: blue; font-style: italic;');
   // inspect each feature
   features.forEach(feature => {
      // build style key for feature
      const styleKey = stylingAttributes.map(function(attr) {
         const featureAttributes = _get(feature, 'attributes');
         // console.log(`${attr}: ${featureAttributes[attr]}`);
         return featureAttributes[attr];
      }).join(fieldDelimiter);

      // get label (prod_type) value from attribute
      const prod_type = _get(feature, 'attributes.prod_type');

      // retrieve style for feature
      // console.log(`checking for style key: ${styleKey}`);
      const matchingStyle = stylingOptions.find(style => {
         return style.value === styleKey;
      });
      if (matchingStyle) {
         // check if matching style label param matches the attribute prod_type field
         if (matchingStyle.label === prod_type) {
            console.log(`style key '${styleKey}' found. The feature and style labels match: '${matchingStyle.label}'`);
         } else {
            console.log(`%cstyle key '${styleKey}' found, but the feature label: '${prod_type}', does not match the style label '${matchingStyle.label}'`,
            'color: white; font-style: italic; background-color: red;padding: 2px');
            noStyleMatch.set(styleKey, [`no label match`, prod_type, feature]);
         }
      } else {
         console.log(`%cno style match found for feature with style key '${styleKey}' and label '${prod_type}'`,
            'color: white; font-style: italic; background-color: red;padding: 2px');
         noStyleMatch.set(styleKey, [`no style match`, prod_type, feature]);
      }
   });
   console.groupEnd();
   console.group(`%csummary: `, 'color: blue; font-style: italic;');
   if (noStyleMatch.size > 0) {
      noStyleMatch.forEach((val, key, map) => {
         console.log(`${val[0]} for feature(s) with style key'${key}' and label '${val[1]}'`);
      })
   } else {
      console.log(`no anomalies detected`);
   }
   console.groupEnd();
   console.groupEnd();
   return noStyleMatch;
}
